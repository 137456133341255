import './App.css';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import { Row, Col, Alert } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BrowserRouter, Routes, Route, NavLink, Link, useLocation } from "react-router-dom";

window.apiRoot = 'https://demo-fintech.jcms.ru/api';
// window.apiRoot = 'http://jcms2/api';
window.siteTitle = 'ФинТех';
var keyPage = '';
var keyNews = '';

function PageHome({loadingApp}) {
  document.title = window.siteTitle;

  loadingApp(false);

  return (
    <Row className="main_row content Carousel-background">
    <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>

    <Col lg={8} xl={8} className="w-100 h-auto p-3">
    <Carousel>

    <Carousel.Item>
      <Carousel.Caption className="d-flex flex-column h-100 align-items-center justify-content-center">
        <h1>Более <span>2000</span> компаний доверяют нам</h1>
        <h2>Компании всех размеров полагаются на нас для цифровой трансформации своих процессов управления кредитами, сборами и дебиторской задолженностью. Благодаря нам они могут быстро масштабировать свои процессы, сохраняя при этом точность и эффективно реагируя на рост объема и сложности транзакций.</h2>
        <Link to="/company"><button>Подробнее о нас</button></Link>
      </Carousel.Caption>
    </Carousel.Item>

    <Carousel.Item>
      <Carousel.Caption className="d-flex flex-column h-100 align-items-center justify-content-center">
        <h1><span>Индивидуальные</span> цены</h1>
        <h2>Повысьте производительность, ускорьте время обработки и поднимите свои кредиты, коллекции и дополненную реальность на новый уровень на одной <span>адаптируемой автоматизированной платформе</span>.</h2>
        <Link to="/pricing" className="nav-link"><button>Подробнее о ценах</button></Link>
      </Carousel.Caption>
    </Carousel.Item>

    <Carousel.Item>
      <Carousel.Caption className="d-flex flex-column h-100 align-items-center justify-content-center">
        <h1>Корпоративное решение</h1>
        <h2>Наша облачная платформа <span>B2B</span> ускоряет обработку транзакций за счет оцифровки и автоматизации данных, задач и процессов. Наши клиенты обычно достигают повышения производительности более чем на 60% в течение первых трех месяцев внедрения.</h2>
        <Link to="/solutions" className="nav-link"><button>Подробнее о платформе</button></Link>
      </Carousel.Caption>
    </Carousel.Item>
    </Carousel>
    </Col>

    <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
    </Row>
  );
}

function PageNotFound({loadingApp}) {
  document.title = `${window.siteTitle} / Страница не найдена!`;

  loadingApp(false);

  return (
    <>
      <Row className="main_row content">
      <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
      <Col lg={8} xl={8} className="p-3 justify-content-center align-items-center d-flex flex-column">
        <h1>Страница не найдена!</h1>
        <p>Кажется, мы не можем найти страницу, которую вы ищете :(</p>
        <Link to="/" className="nav-link">На главную?</Link>
      </Col>
      </Row>
    </>
  );
}

function NewsListView() {
  const location = useLocation();
  const [news, setNews] = useState([]);

  useEffect(() => {
    const apiUrl = `${window.apiRoot}/news/list/`;
    
    const requestOptions = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    };

    if(keyNews !== location.key){
      fetch(apiUrl, requestOptions)
        .then(response => {
          return response.json();
        }).then(data => {
          setNews(data);

          
        }).catch((e) => {
          console.log(e.message);
        });
    }

    keyNews = location.key;
  }, [location]);

  return (
    <>
      <Row className="main_row content">
      <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>

      <Col lg={8} xl={8} className="p-3">
      <Row className="main_row news list">
      {news.map((r)=>
      [
          <Col xxl={4} xl={6} lg={12} key={r.id} className="animation-opacity">
            <div className="p-5 h-100 position-relative">
              <div className="row">
                <div className="date col-6">{r.createdatetime}</div>
                <div className="link col-6 text-end">
                  <Link to={r.id}>Подробнее</Link>
                </div>
              </div>
              <div className="title">{r.title}</div>
              <div dangerouslySetInnerHTML={{__html: r.content.slice(0,255).concat('...')}}></div>
            </div>
          </Col>
      ]
      )}
      </Row>
      </Col>
      <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
      </Row>
    </>
  )
}

function NewsRecView({loadingApp}) {
  const location = useLocation();
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [createdatetime, setСreatedatetime] = useState(null);
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = window.siteTitle;

    const apiUrl = `${window.apiRoot}${location.pathname}`;
    
    const requestOptions = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    };
    setLoading(true);
    loadingApp(true);

    if(keyPage !== location.key){
      fetch(apiUrl, requestOptions)
      .then(response => {
        loadingApp(false);
        setLoading(false);
        return response.json();
      }).then(data => {
        setTitle(data.title);
        setContent(data.content);
        setСreatedatetime(data.createdatetime);

      }).catch((e) => {
        console.log(e.message);
      });
    }

    keyPage = location.key;
  }, [location]);

  if(title !== null){
    document.title = window.siteTitle + ' / ' + title;
  }else{
    document.title = window.siteTitle;
  }

  return (
    <>
      <Row className="main_row content">
      <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>

      {loading?
        <Col lg={8} xl={8} className="d-flex justify-content-center align-items-center w-100 h-100"><div className="App-loading"></div></Col>
        :
        <>
          <Col lg={8} xl={8} className="p-3 text-center animation-opacity"><h1>{title}</h1></Col>
        </>
      }

      <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
      </Row>

      <Row className="main_row news whiteblock">
        <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
        <Col lg={8} xl={8} className="p-3 date animation-opacity" dangerouslySetInnerHTML={{__html: createdatetime}}></Col>
        <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>

        <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
        <Col lg={8} xl={8} className="p-3 animation-opacity" dangerouslySetInnerHTML={{__html: content}}></Col>
        <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
      </Row>
    </>
  );
}

function FormView() {
  const [validated, setValidated] = useState(false);
  
  const [allok, setAllok] = useState(false);
  
  const [name, setName] = useState('');
  const [nameerror, setNameError] = useState(false);

  const [email, setEmail] = useState('');
  const [emailerror, setEmailError] = useState(false);

  const [text, setText] = useState('');
  const [texterror, setTextError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
        event.stopPropagation();

        const response = await fetch(`${window.apiRoot}/contacts/`, {
            method: "POST",
            headers: { "Accept": "application/json", "Content-Type": "application/json"},
            body: JSON.stringify([{
                name: name,
                email: email,
                text: text,
            }]),
        });

        if (response.ok === true) {
          response.text().then(function (message) {
            if(message === 'email error'){
              setEmailError(true);
              setValidated(false);
            }else{
              console.log(message);
              setAllok(true);
              setName('');
              setEmail('');
              setText('');

              setNameError(false);
              setEmailError(false);
              setTextError(false);
              setValidated(false);
            }
          });
        }
    }

    setValidated(true);
  };

  const validateName = (name) => {
    const charsRegex = /^[а-яА-Яa-zA-Z\s]+$/;
    
    if(charsRegex.test(name)){
        setNameError(false);
        setValidated(true);
    }else{
        setNameError(true);
        setValidated(false);
    }
  }

  const validateEmail = (name) => {
    const charsRegex = /^[.a-zA-Z@-]+$/;
    
    if(charsRegex.test(name)){
      setEmailError(false);
      setValidated(true);
    }else{
      setEmailError(true);
      setValidated(false);
    }
  }

  return (
    <>
      <Row className="main_row content">
      <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
      <Col lg={4} xl={4} className="p-3 text-start">
      <h2 className="text-start">Нужна персональная помощь?</h2>
      <h3>Мы здесь, чтобы помочь ответить на любые ваши вопросы о {window.siteTitle}.</h3>
      </Col>

      <Col lg={4} xl={4} className="p-3 text-start">
      

      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        {(allok)?<Alert variant={'primary'} style={{marginBottom: '15px'}}>Ваше сообщение успешно отправлено! Мы ответим Вам в ближайшее время. Спасибо!</Alert>:''}

        <Form.Group className="mb-3" controlId="form.ControlInput1">
            <Form.Label>Как к Вам обращаться?</Form.Label>
            <Form.Control aria-describedby="nameHelpBlock" type="name" value={name} required isInvalid={nameerror} onChange={(e)=>{setName(e.target.value);validateName(e.target.value)}} />
            <Form.Control.Feedback type="invalid">Поле не заполнено или использованы цифры и служебные символы.</Form.Control.Feedback>
            <Form.Text id="nameHelpBlock">Напишите как к Вам удобнее всего обращаться.</Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="form.ControlInput2">
            <Form.Label>Электронная почта</Form.Label>
            <Form.Control type="email" value={email} required placeholder="name@example.ru" isInvalid={emailerror} onChange={(e)=>{setEmail(e.target.value);validateEmail(e.target.value)}} />
            <Form.Control.Feedback type="invalid">Поле не заполнено или некорректный адрес электронной почты.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="form.ControlTextarea1">
            <Form.Label>Вопросы/комментарии</Form.Label>
            <Form.Control as="textarea" rows={3} value={text} required placeholder="" isInvalid={texterror} onChange={(e)=>{setText(e.target.value)}} />
            <Form.Control.Feedback type="invalid">Поле не заполнено.</Form.Control.Feedback>
        </Form.Group>

        <Button type="submit">Отправить</Button>
      </Form>


      </Col>
      <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
      </Row>
    </>
  )
}

function PageView({loadingApp}) {
  const location = useLocation();
  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  
  const [block1, setBlock1] = useState(null);
  const [block2, setBlock2] = useState(null);
  const [block3, setBlock3] = useState(null);
  const [block4, setBlock4] = useState(null);

  const [loading, setLoading] = useState(true);
  
  const [news, setNews] = useState(false);
  const [customers, setCustomers] = useState(false);
  const [solutions, setSolutions] = useState(false);
  const [pricing, setPricing] = useState(false);
  const [company, setСompany] = useState(false);
  const [contacts, setContacts] = useState(false);

  useEffect(() => {
    document.title = window.siteTitle;

    const apiUrl = `${window.apiRoot}${location.pathname}`;
    
    const requestOptions = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    };

    setNews(false);
    setCustomers(false);
    setSolutions(false);
    setPricing(false);
    setСompany(false);
    setContacts(false);
    setLoading(true);

    loadingApp(true);

    if(keyPage !== location.key){
      fetch(apiUrl, requestOptions)
      .then(response => {
        setLoading(false);
        return response.json();
      }).then(data => {
        
        if(location.pathname === "/news"){
          setNews(true);
          setBlock1(data.block1);
        }
        if(location.pathname === "/customers"){
          setCustomers(true);
          setBlock1(data.block1);
          setBlock2(data.block2);
        }
        if(location.pathname === "/solutions"){
          setSolutions(true);
          setBlock1(data.block1);
        }
        if(location.pathname === "/pricing"){
          setPricing(true);
          setBlock1(data.block1);
        }
        if(location.pathname === "/company"){
          setСompany(true);

          setBlock1(data.block1);
          setBlock2(data.block2);
          setBlock3(data.block3);
          setBlock4(data.block4);
        }
        if(location.pathname === "/contacts"){
          setContacts(true);
          setBlock1(data.block1);
        }
        setTitle(data.title);
        setText(data.text);

        loadingApp(false);
      }).catch((e) => {
        console.log(e.message);
      });
    }

    keyPage = location.key;

  }, [location]);

  if(title !== null){
    document.title = window.siteTitle + ' / ' + title;
  }else{
    document.title = window.siteTitle;
  }

  return (
    <>
      <Row className="main_row content">
      <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>

      {loading?
        <Col lg={8} xl={8} className="d-flex justify-content-center align-items-center w-100 h-100"><div className="App-loading"></div></Col>
        :
        <>
          <Col lg={8} xl={8} className="p-3 text-center animation-opacity"><h1>{title}</h1><p>{text}</p></Col>
        </>
      }

      <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
      </Row>

      {news?
        <>
          <Row className="main_row news whiteblock">
          <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          <Col lg={8} xl={8} className="p-3 animation-opacity" dangerouslySetInnerHTML={{__html: block1}}></Col>
          <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          </Row>
        </>
      :""}

      {customers?
        <>
          <Row className="main_row whiteblock">
            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
            <Col lg={8} xl={8} className="p-3 animation-opacity" dangerouslySetInnerHTML={{__html: block1}}></Col>
            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          </Row>
          <Row className="main_row whiteblock">
            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
            <Col lg={8} xl={8} className="p-3 animation-opacity" dangerouslySetInnerHTML={{__html: block2}}></Col>
            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          </Row>
        </>
      :""}

      {solutions?
        <>
        </>
      :""}

      {pricing?
        <>
          <Row className="main_row pricing whiteblock">
          <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          <Col lg={8} xl={8} className="p-3" dangerouslySetInnerHTML={{__html: block1}}>
          
          </Col>
          <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>

          <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          
          <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>

          </Row>
        </>
      :""}

      {company?
        <>
          <Row className="main_row about whiteblock">
            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
            <Col lg={8} xl={8} className="p-3 animation-opacity"><h2>Наша история</h2></Col>
            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>

            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
            <Col xxl={4} xl={4} lg={4} className="p-5 dark_block animation-opacity" dangerouslySetInnerHTML={{__html: block1}}></Col>
            <Col xxl={4} xl={4} lg={4} className="p-5 gray_block animation-opacity" dangerouslySetInnerHTML={{__html: block2}}></Col>
            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          </Row>
        </>
      :""}

      {contacts?
        <>
          <Row className="main_row news whiteblock">
          <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          <Col lg={8} xl={8} className="p-3" dangerouslySetInnerHTML={{__html: block1}}></Col>
          <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          </Row>
        </>
      :""}

    </>
  );
}

function App() {
  const [loading, setLoading] = useState(true);
  const handleLoading = (load) => {
    setLoading(load);
  }; 

  return (
      <div className={loading ? 'App-main unload' : 'App-main load'}>
        <BrowserRouter>  
          <Row className="App-navbar header main_row w-100">
            <Col lg={2} xl={2}></Col>
            <Col lg={8} xl={8}>
              <Navbar expand="lg">
                <Navbar.Brand><Link to="/"><div className="fintech-logo"></div></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <Nav>
                  <Nav.Item><NavLink as={NavLink} to="/news" className="nav-link">Новости</NavLink></Nav.Item>
                  <Nav.Item><NavLink as={NavLink} to="/customers" className="nav-link">Клиенты</NavLink></Nav.Item>
                  <Nav.Item><NavLink as={NavLink} to="/solutions" className="nav-link">Решения</NavLink></Nav.Item>
                  <Nav.Item><NavLink as={NavLink} to="/pricing" className="nav-link">Цены</NavLink></Nav.Item>
                  <Nav.Item><NavLink as={NavLink} to="/company" className="nav-link">Компания</NavLink></Nav.Item>
                  <Nav.Item><NavLink as={NavLink} to="/contacts" className="nav-link">Контакты</NavLink></Nav.Item>

                  {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
            <Col lg={2} xl={2}></Col>
          </Row>


              <Routes>
                <Route index element={<PageHome loadingApp={handleLoading} />} />
                <Route path="/news" element={<><PageView loadingApp={handleLoading} /><NewsListView /></>} />
                <Route path="/news/*" element={<><NewsRecView loadingApp={handleLoading} /></>} />
                <Route path="/customers" element={<PageView loadingApp={handleLoading} />} />
                <Route path="/solutions" element={<PageView loadingApp={handleLoading} />} />
                <Route path="/pricing" element={<PageView loadingApp={handleLoading} />} />
                <Route path="/company" element={<PageView loadingApp={handleLoading} />} />
                <Route path="/contacts" element={<><PageView loadingApp={handleLoading} /><FormView /></>} />
                <Route path="*" element={<PageNotFound loadingApp={handleLoading} />} />
              </Routes>

          

          <Row className="App-navbar main_row footer">
          <Col xl={2}></Col>
          <Col xl={6}><Navbar expand="lg" className="App-footer">© IT-компания JAS 2024. Сделано с помощью модульной системы управления JCMS. Данный сайт является демонстрационной версией.<br />Вся информация на сайте несёт исключительно демонстрационный характер.</Navbar></Col>
          <Col xl={2} className="logos text-center p-3">
          <a href="https://www.jas.ru"><div className="jas-logo d-inline-block m-3"></div></a>
          <a href="https://www.jcms.ru"><div className="jcms-logo d-inline-block m-3"></div></a>
          </Col>
          <Col xl={2}></Col>
          </Row>
        </BrowserRouter>
      </div>
  );
}

export default App;
